@import "../../styles/vars";

.wrapper {
  background: #ffffff;
  box-shadow: 0 10px 25px 1px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  display: flex;
  padding: 6px 6px 6px 20px;
  margin: 0 12px 20px;
  position: relative;

  &:before {
    content: "City";
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    top: 8px;
    left: 21px;
  }

  select {
    border: none !important;
    outline: none !important;
    border-radius: 20px;
    background: white !important;
    flex-grow: 1;
    margin-top: 10px;
    appearance: none !important;
    color: $textColor !important;
  }

  @media (min-width: $xl) {
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-45%);
    min-width: 400px;
  }
  @media (min-width: $xxl) {
    min-width: 500px;
    transform: translateX(-50%);
  }
}

.button {
  border-radius: 50% !important;
  width: 44px;
  height: 44px;
  min-height: 44px;
  min-width: 44px;
  margin-left: 15px;
}
