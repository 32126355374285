@import "../../../styles/vars";

.mainOffice + li {
  padding-left: 10px;
  @media (min-width: $sm) {
    padding-left: 15px;
  }
  &:before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -15px;
    height: 30px;
    width: 1px;
    background: #c4c4c4;
    @media (min-width: $sm) {
      margin-top: -25px;
      height: 50px;
    }
  }
}

.page_header {
  border-bottom: 1px solid #c4c4c4;

  .search_menu {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    position: relative;
    &:before {
      content: "ON-DEMAND";
      position: absolute;
      font-weight: 450;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.02em;
      color: #777777;
      left: 0;
      top: -5px;
      @media (min-width: $sm) {
        font-size: 12px;
      }
    }

    &.search_menu_main_office {
      &:before {
        left: 98px;
        @media (min-width: $sm) {
          left: 122px;
        }
        @media (min-width: $lg) {
          left: 122px;
        }
        @media (min-width: $xl) {
          left: 132px;
        }
      }
    }

    li {
      position: relative;
      margin: 0 5px 0 0;
      padding-right: 5px;
      @media (min-width: $sm) {
        margin-right: 10px;
        padding-right: 10px;
      }

      &:last-child {
        margin-right: 0;
        padding-right: 0;

        &:after {
          display: none !important;
        }
      }

      button {
        padding: 10px 0 !important;
        font-size: 20px;
        font-weight: 500;
        line-height: 2;
        position: relative;
        border: none !important;
        @include font(16px, 18px, 20px, 2, 2, 2);

        &:after {
          position: absolute;
          bottom: 12px;
          left: 0;
          width: 100%;
          height: 2px;
          background: black;
          content: "";
          opacity: 0;
        }
      }
    }
  }
}

.active {
  &:after {
    opacity: 1 !important;
  }
}

.filter_button {
  border: 1.5px solid #444444 !important;
  padding: 5px 20px !important;
  min-width: 120px;
  text-align: center;
  height: 34px;
  border-radius: 17px !important;
  outline: none !important;
  box-shadow: none !important;
}

.map_wrapper {
  overflow: hidden !important;
  z-index: -1;
  height: 400px;
  @media (min-width: $md) {
    height: 100vh;
  }
}

.location_summary_block {
  display: block;
  position: relative;
  margin-bottom: 24px;
  @media (min-width: $xl) {
    border-bottom: 1px solid $borderColor;
    margin-bottom: 16px;
    padding-bottom: 16px;
    &:last-child {
      border-bottom: none;
    }
  }

  h4 {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (min-width: $xl) {
      max-width: 100%;
    }
  }

  & > a:first-child {
    display: block;
    color: black !important;
    text-decoration: none !important;

    & > div:first-child {
      width: 100%;
      @media (min-width: $xl) {
        width: 50%;
      }

      & > div {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: calc(100% / 1.6180339);
        margin-bottom: 5px;

        img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    @media (min-width: $xl) {
      display: flex;
      & > div:nth-child(2) {
        width: 50%;
        padding-left: 10px;
      }
    }
  }

  & > div {
    a {
      color: $green;
    }

    @media (min-width: $xl) {
      position: absolute;
      right: 0;
      bottom: 16px;
      text-align: right;
    }
  }
}

.sticky_map_button {
  z-index: 999;

  button {
    background: black;
    padding: 6px 15px;
    color: white !important;
    border: 2px solid #ecd295;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    svg {
      margin-left: 5px;
    }
  }
}

.main_office_block {
  background: white;
  width: 100%;
  max-width: 550px;
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto 30px;
  @media (min-width: $lg) {
    padding: 30px;
  }

  h4 {
    color: $black;
    @include font(16px, 18px, 18px);
  }

  p {
    @include font(14px, 15px, 15px);
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;

      img {
        border: 1px solid $green;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 10px;
      }

      p {
        border: 1px solid $green;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        object-fit: cover;
        margin: 0 10px 0 0;
        line-height: 28px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        background: #ddd;
      }

      span {
        @include font(12px, 12px, 14px);
      }
    }
  }
}

.z_index_fix {
  & > div {
    &:nth-child(1) {
      z-index: 2;
    }
    &:nth-child(2) {
      z-index: 1;
    }
  }
}

.price_block {
  margin-top: -5px;
  @media (min-width: $xl) {
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.active_results {
  bottom: 38px;
}

.search_filters_wrapper {
  @media (min-width: $md) {
    width: 38.5vw;
  }
  @media (min-width: $lg) {
    width: 47.5vw;
  }
  @media (min-width: $xxl) {
    width: 45vw;
    max-width: 750px;
  }
  @media (min-width: 1440px) {
    width: 48vw;
    max-width: 775px;
  }
  @media (min-width: 1920px) {
    max-width: 935px;
  }
  @media (min-width: 2560px) {
    max-width: 940px;
  }
}
