@import "../../../../styles/vars";

.wrapper {
  position: sticky;
  top: 20px;

  & > div {
    &:first-child {
      background: #ffffff;

      border-radius: 10px;
      @media (min-width: $lg) {
        padding: 30px;
        border: 1px solid #bbbbbb;
        box-shadow: 2px 25px 25px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
