.time_picker_main_wrapper {
  border-radius: 0 0 3px 3px !important;
  border: 1px solid #777777 !important;
  margin-top: -1px;
  position: relative;
  &>small {
    position: absolute;
    left: 16px;
    top: 10px;
    pointer-events: none;
  }
}

.select_input {
  border-radius: 0 0 3px 3px !important;
  border: none !important;
  padding: 30px 16px 9px !important;
}
