@import "../../../styles/vars";

.list {
  display: flex;
  align-items: center;
  margin-top: 8px;
  @media (min-width: $xl) {
    display: block;
    position: absolute;
    left: calc(50% + 10px);
    bottom: 16px;
  }
  span {
    display: block;
    line-height: 1.2;
    @include font(12px, 12px, 14px);
  }
  ul {
    display: flex;
    list-style-type: none;
    margin: 0 0 0 auto;
    padding: 0;
    flex-wrap: nowrap;
    overflow: hidden;
    li {
      margin-top: 4px;
      p, img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        font-size: 12px;
        font-weight: bold;
        line-height: 30px;
        text-align: center;
        margin-left: 2px;
        @media (min-width: $lg) {
          margin-left: 0;
          margin-right: 2px;
        }
      }
      p {
        background: black;
        color: white;
      }
    }
  }
}
