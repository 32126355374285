@import "../../styles/vars";

.page_header {
  border-bottom: 1px solid #c4c4c4;
}

.filter_button {
  border: 1.5px solid #444444 !important;
  padding: 4px 20px !important;
  min-width: 120px;
  text-align: center;
  height: 34px;
  font-size: 16px !important;
  border-radius: 17px !important;
  outline: none !important;
  box-shadow: none !important;
}

.map_wrapper {
  overflow: hidden !important;
  z-index: -1;
  height: 400px;
  @media (min-width: $md) {
    height: 100vh;
  }
}

.location_summary_block {
  display: block;
  position: relative;
  margin-bottom: 16px;
  @media (min-width: $lg) {
    border-bottom: 1px solid $borderColor;
    margin-bottom: 16px;
    padding-bottom: 16px;
    &:last-child {
      border-bottom: none;
    }
  }

  & > a:first-child {
    display: block;
    color: black !important;
    text-decoration: none !important;

    & > div:first-child {
      width: 100%;
      @media (min-width: $lg) {
        width: 50%;
      }

      & > div {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: calc(100% / 1.6180339);

        img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    @media (min-width: $lg) {
      display: flex;
      & > div:nth-child(2) {
        width: 50%;
        padding-left: 10px;
      }
    }
  }

  & > div {
    a {
      color: $green;
    }

    @media (min-width: $lg) {
      position: absolute;
      right: 0;
      bottom: 16px;
      text-align: right;
    }
  }
}

.sticky_map_button {
  z-index: 999;

  button {
    background: black;
    padding: 6px 15px;
    color: white !important;
    border: 2px solid #ecd295;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    svg {
      margin-left: 5px;
    }
  }
}

.meeting_room_profile_button {
  border: 1px solid #777777 !important;
  border-radius: 3px;
  width: 100%;
  text-align: left !important;
  padding: 8px 16px !important;
  line-height: 1.2;
  color: $black;

  small {
    display: block;
    line-height: 1.52;
  }
}

.isLast {
  border-radius: 0 0 3px 3px !important;
  margin-bottom: 16px !important;
}

.read_only_mr_filter {
  pointer-events: none !important;
}

.read_only_mr_filter_with_opacity {
  opacity: 0.4;
}
