@import "../../../../styles/vars";

.thumbnail {
  width: 150px;
  height: 84px;
  object-fit: cover;
  @media (min-width: $md) {
    order: 2;
    margin-left: auto;
  }
}

.summary_list {
  list-style-type: none;
  margin: 30px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    display: flex;
    flex-direction: column;
    flex: 50%;
    margin: 10px 0;

    span {
      display: block;

      &:first-child {
        order: 2 !important;
        @include font(12px, 13px, 14px);
        text-transform: uppercase;
      }

      &:last-child {
        order: 1 !important;
        font-weight: 500;
        @include font(16px, 18px, 20px);
      }
    }
  }
}

.cancellation_policy {
  padding-left: 21px;
  border-left: 6px solid $theme;
  margin: 40px 0;
}
