@import "../../styles/vars";

.image_zoom_container {
  margin-bottom: 10px;
  width: 100%;
  @media (min-width: $lg) {
    margin-bottom: 20px;
  }

  button {
    width: 100%;
    height: 0;
    padding: 0 0 56.25% 0;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      object-fit: cover;
    }
  }
}

.first_image {
  @media (min-width: $lg) {
    padding-bottom: 58.75% !important;
  }
  @media (min-width: $xl) {
    padding-bottom: 58.2% !important;
  }
}
