.userName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    margin: 0;
    color: white;
    background: #008060;
  }
  p, img {
    display: block;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    border: 1px solid #008060;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
  small {
    margin-left: 8px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
