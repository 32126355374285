@import "../_vars";
@import url("https://use.typekit.net/vjc8ttv.css");

// Your variable overrides
$primary: $theme;
$secondary: $green;

$border-radius: 4px;
$border-radius-lg: 4px;
$border-radius-sm: 4px;

$font-family-sans-serif: $font;

$input-border-color: #777;
$input-focus-border-color: #777;
$input-padding-y: 12px;

$btn-border-radius: 2px;
$btn-border-radius-lg: 2px;
$btn-border-radius-sm: 2px;
$btn-padding-y: 10px;
$btn-padding-x: 25px;
$btn-font-family: $font;
//$btn-font-size: $input-btn-font-size;
//$btn-line-height: $input-btn-line-height;
$btn-white-space: nowrap; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: 5px;
$btn-padding-x-sm: 16px;
//$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: 12px;
$btn-padding-x-lg: 30px;

//$input-focus-box-shadow: none;
$input-btn-focus-box-shadow: none !important;
$form-select-focus-box-shadow: none !important;

//$input-btn-border-width: 0px;
//$btn-border-width: 0px;

// Bootstrap and its default variables
@import "../../../node_modules/bootstrap/scss/bootstrap";

.btn {
  &.btn-default {
    border-color: transparent;
  }
}

.navbar {
  height: 60px;
  @media (min-width: $lg) {
    height: 80px;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: white !important;

  &.btn-primary {
    color: $black !important;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: $black !important;
}

.btn-primary {
  color: $black !important;
  font-weight: 500;
}

.btn-secondary {
  background: transparent !important;
  color: $green !important;
}

.text-theme {
  color: $theme !important;
}

.text-green {
  color: $green !important;
}

.container.outer,
main > .container,
main > * > .container {
  padding-left: 25px;
  padding-right: 25px;
}

p {
  @include font(16px, 18px, 20px);
}

.row.image-gallery-grid {
  margin-left: -5px;
  margin-right: -5px;

  & > div {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media (min-width: $lg) {
    margin-left: -8px;
    margin-right: -8px;
    & > div {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.StripeElement {
  padding: 15px 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.container-fluid {
  max-width: 1920px;
}

body {
  overflow-x: hidden !important;
  overflow-y: scroll;
}

body,
html {
  scroll-behavior: auto !important;
}

.Toastify__toast.Toastify__toast-theme--colored {
  font-family: inherit;

  &.Toastify__toast--success {
    background: #2ecc71;
  }

  &.Toastify__toast--warning {
    background: #f1c40f;
  }

  &.Toastify__toast--info {
    background: #3498db;
  }

  &.Toastify__toast--error {
    background: #e74c3c;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

strong {
  font-weight: 500 !important;
}
