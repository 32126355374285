@import "../../../../styles/vars";

.categories_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 2px;
  &::-webkit-scrollbar {
    display: none;
  }
  button {
    display: block;
    padding: 0;
    margin-right: 32px;
    text-align: center;
    &:last-child {
      margin-right: 0;
    }
    span {
      display: inline-block;
      text-align: center;
      margin: 0;
      line-height: 1;
    }
    img {
      display: block;
      margin: 0 auto 5px;
      height: 30px;
    }
  }

  @media (min-width: $md) {
    margin-bottom: 16px;
  }

  & + div {
    @media (min-width: $md) {
      position: absolute;
    }
  }
}

.active {
  text-decoration: underline !important;
  //font-weight: bold !important;
  span {
    position: relative;
    &:after {
      position: absolute;
      content: "";
      height: 2px;
      width: 100%;
      background: black;
      left: 0;
      bottom: -5px;
    }
  }
}
