.meeting_room_hero_image {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  width: 100%;
  & > img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
