@import "../../styles/vars";

.wrapper {
  transition: opacity 0.2s linear;
  opacity: 0;
  pointer-events: none;
  display: block;
  height: 20px;
  width: 36px;
  margin: 0 auto;
  padding: 0 !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  small {
    white-space: nowrap;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -100%;
  }

  span {
    padding: 0 !important;
    position: static !important;
    display: block;
    float: left;
    margin: 6px 2px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
  }
}

.smaller {
  zoom: 0.8;
}

.progress {
  opacity: 1;
  transition-delay: 0.2s;
  background: transparent;

  span {
    animation: dot-progress 1s linear infinite;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes dot-progress {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
