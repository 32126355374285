@import "../../../../styles/vars";

.wrapper {
  position: sticky;
  top: 20px;
  &>div:first-child {
    display: block;
    background: #FFFFFF;
    @media (min-width: $lg) {
      border: 1px solid #BBBBBB;
      box-sizing: border-box;
      box-shadow: 2px 25px 25px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 30px;
    }
  }
}


.message_box {
  margin-top: 50px;
  border: 1px solid #BBBBBB;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px;
  a {
    color: inherit !important;
  }
}