.user_image {
  display: block;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: relative;
  background: #ccc;
  img {
    background: white;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  p {
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    font-weight: 500;
  }

  & > div {
    opacity: 0;
  }
}
