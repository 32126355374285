$theme: #ecd295;
$lightTheme: #f5f1ea;
$secondary: #000;

$titleColor: #212529;
$textColor: #212529;

$green: #008060;
$black: #1a1a1a;
$grey: #444;
$lightGrey: #777777;

$success: #04ba16;
$borderColor: #c4c4c4;

$font: futura-pt, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$dark: #1a1a1a;

$styled-font: "Parisienne", cursive;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;

$tablet: $sm;
$desktop: $xl;

@mixin font($mf, $tf, $df, $ml: normal, $tl: normal, $dl: normal) {
  font-size: $mf;
  line-height: $ml;
  @media (min-width: $tablet) {
    font-size: $tf;
    line-height: $tl;
  }
  @media (min-width: $desktop) {
    font-size: $df;
    line-height: $dl;
  }
}
