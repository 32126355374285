.company_logo {
  position: relative;
  width: 120px;
  height: 50px;

  & > input, img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  img {
    object-fit: cover;
    z-index: 1;
  }

  input {
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }
}
