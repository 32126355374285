@import "../styles/vars";

.nav_header {
  border-bottom: 1px solid $borderColor;
  margin-bottom: 50px;

  a {
    display: inline-block;
    @include font(18px, 20px, 24px);
    margin: 0 30px 0 0;
    position: relative;
    font-weight: 500;
    padding: 10px 0;
    color: #444444 !important;
    text-decoration: none !important;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 2px;
      background: #444;
      opacity: 0;
    }
  }
}

.active_link {
  &:before {
    opacity: 1 !important;
  }
}
