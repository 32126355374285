.booking_type_badge {
  position: absolute;
  z-index: 2;
  background: white;
  left: 8px;
  top: 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  line-height: 15px;
  color: #444444;
  img {
    display: block;
    position: relative !important;
    height: 15px !important;
    width: auto !important;
    margin-right: 2px;
  }
}
