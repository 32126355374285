@import "./vars";

@keyframes flash {
  0% {
    transform: scale(1);
  }
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1);
  }
  78% {
    transform: scale(1.25);
  }
  86% {
    transform: scale(1);
  }
  92% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.animate-flash {
  animation: flash 2s linear;
  animation-iteration-count: 5;
}

.message-box {
  margin-top: 50px;
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
  @media (min-width: $sm) {
    padding: 30px;
  }
  a {
    color: inherit !important;
  }
}

.results-list-container-fix-for-filters {
  @media (min-width: $md) {
    padding-top: 60px !important;
  }
}
