.input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.message {
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px dashed #ddd;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
}
