@import "../../../../styles/vars";

.review_block {
  display: block;
  margin-top: 30px;
  @media (min-width: $lg) {
    margin-top: 40px;
  }
  p {
    margin: 0;
  }
  small {
    display: block;
    margin-bottom: 15px;
  }
  & > span {
    display: block;
    margin: 5px 0;
  }
}

.stars {
  color: #fbbd03;
  display: flex;
  align-items: center;
}
