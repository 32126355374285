.booking_block {
  display: block;

  img {
    display: block;
    width: 100%;
  }
}

.image_wrapper {
  height: 0;
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  background: #eee;

  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.details_wrapper {
  display: block;
  position: relative;

  ul {
    position: absolute;
    right: 0;
    top: 8px;
    display: flex;
    cursor: pointer;

    li {
      margin-left: -12px;
      height: 26px;
      width: 26px;
      text-align: center;
      line-height: 26px;
      border-radius: 50%;
      font-size: 12px;
      font-weight: 500;
      color: black;
      background: #ddd;
      border: 1px solid #bbb;
      position: relative;

      p {
        font-size: inherit;
        margin: 0;
        line-height: 26px;
        font-weight: inherit;
      }

      img {
        height: 26px;
        width: 26px;
        border-radius: 50%;
        object-fit: cover;
        display: inline-block;
        line-height: 0;
        font-size: 0;
        position: absolute;
        left: 0;
        top: 0;
      }

      display: none;

      &:nth-last-child(1) {
        display: inline-block;
        background: #ECD295;
        border: 1px solid #ECD295;
      }

      &:nth-last-child(2) {
        display: inline-block;
        background: #008060;
        border: 1px solid #008060;
      }

      &:nth-last-child(3) {
        display: inline-block;
        background: #7B61FF;
        border: 1px solid #7B61FF;
      }

      &:nth-last-child(4) {
        display: inline-block;
        background: #ddd;
      }
    }

    &:hover li {
      display: inline-block;
    }
  }
}


.users_list {
  li {
    &:nth-last-child(1) p:nth-child(1) {
      display: inline-block;
      background: #ECD295;
    }

    &:nth-last-child(2) p:nth-child(1) {
      display: inline-block;
      background: #008060;
    }

    &:nth-last-child(3) p:nth-child(1) {
      display: inline-block;
      background: #7B61FF;
    }

    &:nth-last-child(4) p:nth-child(1) {
      display: inline-block;
      background: #ddd;
    }
  }

  p {
    margin: 0;

    &:nth-child(1) {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      background: #eee;
      text-align: center;
      line-height: 35px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    object-fit: cover;
  }
}
