@import "../../styles/vars";

.filter_wrapper {
  display: inline-block;
  margin-right: 12px;

  & > button {
    font-weight: 500;
    padding: 4px 10px 3px;
    border: 1.5px solid #444444 !important;
    border-radius: 17px;
    min-width: 120px;
    &:after,
    &:before {
      display: none !important;
      visibility: hidden !important;
    }
  }

  & > div {
    margin-top: 10px;
  }
}

.ts_array {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  min-width: 250px;
  padding: 4px;

  li {
    flex: 0 1 calc(20% - 8px);
    margin: 4px;
    text-align: center;

    button {
      color: #444 !important;
      margin: 0;
      display: inline-block;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      padding: 0;
      line-height: 30px;
      text-align: center;
      border: 1px solid #777;

      &.active {
        background: $theme;
      }
    }
  }
}
