@import "../../../../../../styles/vars";

.ts_main_wrapper {
  margin-top: 16px;

  & + div {
    margin-top: -1px !important;
    padding-top: 0 !important;

    //& > div > div > button {
    //  border-radius: 0 !important;
    //}
  }
}

.filter_wrapper {
  display: inline-block;
  width: 100%;

  & > button {
    border: 1px solid #777777 !important;
    border-radius: 3px 3px 0 0;
    width: 100%;
    padding: 8px 16px !important;
    line-height: 1.35;
    color: $black;
    font-weight: normal;
    text-align: left;

    &:after, &:before {
      display: none !important;
      visibility: hidden !important;
    }
  }

  & > div {
    margin-right: 10px;
  }
}

.ts_array {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  min-width: 250px;
  max-width: 300px;
  padding: 4px;

  li {
    flex: 0 1 calc(20% - 8px);
    margin: 4px;
    text-align: center;

    button {
      color: #444 !important;
      margin: 0;
      display: inline-block;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      padding: 0;
      line-height: 30px;
      text-align: center;
      border: 1px solid #777;

      &.active {
        background: $theme;
      }
    }
  }
}


.readOnly {
  pointer-events: none !important;
}
