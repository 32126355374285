.userImageList {
  display: flex;
  list-style-type: none;
  padding: 0;

  li {
    position: relative;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    margin-left: -22px;
    display: none;
    text-decoration: none !important;
    z-index: 1;

    &:nth-child(1) {
      margin-left: 0;
      z-index: 3;
      display: block;
    }

    &:nth-child(2) {
      margin-left: -44px;
      z-index: 2;
      display: block;
    }

    img, p {
      height: 35px;
      text-decoration: none !important;
      width: 35px;
      border-radius: 50%;
      color: white;
      line-height: 35px;
      font-size: 15px;
      text-transform: uppercase;
      border: 1px solid #444;
    }

    img {
      object-fit: cover;
      background: white;
    }

    p {
      background: #333;
      margin: 0 !important;
    }
  }
}

.link {
  text-decoration: none !important;
  display: block;
  position: relative;
  width: 35px;
  transform: translate(-50%, -50%);
}

.plain_marker {
  text-decoration: none !important;
  display: block;
  position: relative;
  width: 50px;
  transform: translate(-50%, -100%);
}

.userInMap {
  text-align: left;
  margin: 5px 0;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  text-overflow: ellipsis;
  align-items: center;

  img, p {
    text-decoration: none !important;
    height: 30px;
    min-height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 50%;
    background: #eee;
    margin: 0 5px 0 0;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
  }
}
