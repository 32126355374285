@import "../../styles/vars";

.user_menu {
  border: 1px solid #dfe1e6 !important;
  height: 46px;
  border-radius: 23px;
  padding: 0 8px;
  min-width: 70px;
  background: white;
  appearance: none;
  color: $textColor !important;

  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
  }

  svg {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 5px;

    &:nth-child(1) {
      margin-right: 5px;
    }

    &:nth-child(2) {
      background: #eee;
    }
  }
}

.main_logo {
  display: flex;
  align-items: center;

  img {
    width: 120px;
    @media (min-width: $lg) {
      width: 150px;
    }
  }
}

.client_logo {
  border-left: 1px solid #dfe1e6;

  img {
    height: 40px;
    @media (min-width: $lg) {
      height: 50px;
    }
  }
}

.header {
  z-index: 3;
  position: relative;

  & + div,
  & + div + div {
    z-index: 1;
    position: relative;
  }

  strong a {
    color: inherit;
  }
}
