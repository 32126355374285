@import "./vars";

.react-datepicker {
  font-family: "futura-pt", sans-serif;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: white;
}

.react-datepicker__header {
  background-color: white !important;
}

.react-datepicker__day {
  border-radius: 50% !important;
}

.react-datepicker__day--keyboard-selected {
  background: white;
  color: black;
}

.react-datepicker__day--selected {
  background-color: $theme !important;
  color: black;
}

.react-datepicker__day--weekend {
  pointer-events: none !important;
  background-color: white !important;
  color: #ccc !important;
}

.react-datepicker-wrapper {
  width: 100%;
}
