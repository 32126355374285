@import "../../../../../../styles/vars";

.filter_button {
  border: 1px solid #777777 !important;
  border-radius: 3px;
  width: 100%;
  text-align: left !important;
  padding: 8px 16px !important;
  line-height: 1.2;
  color: $black;

  small {
    display: block;
    line-height: 1.52;
  }
}


.readOnly {
  pointer-events: none !important;
}

.isLast {
  border-radius: 0 0 3px 3px !important;
  margin-bottom: 16px !important;
}
