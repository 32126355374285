.no_caret {
  padding: 5px;

  &:after, &:before {
    display: none !important;
  }
}


.name {
  display: flex;

  & > div {
    &:first-child {
      img, p {
        height: 48px;
        width: 48px;
        object-fit: cover;
        border-radius: 50%;
        margin: 0 15px 0 0;
        line-height: 48px;
        text-align: center;
        display: block;
        background: #eee;
      }
    }
  }
}
